import React, { FC } from 'react'

import { AGE_GATE_UNDER_AGE_CLICKED } from '@juullabs/juulio-bridge/common/constants/segmentEvents'
import { Button } from '@juullabs/react-components'

import { TranslatedTextMessage } from 'components/ageGate/components/TranslatedTextMessage'
import { AgeGateContext } from 'components/ageGate/contexts/AgeGateContext'
import { segmentTrack } from 'lib/segment'
import { useTranslations } from 'utils/translations/useTranslations'

import styles from './DisagreeButton.module.scss'

type Props = {
  minimumAge: number
  shouldTrackAgeGateEvents?: boolean
}

export const DisagreeButton: FC<Props> = ({
  minimumAge,
  shouldTrackAgeGateEvents,
}) => {
  const { translateMessage } = useTranslations()

  const { messageKeys } = React.useContext(AgeGateContext)
  const handleOnClick = () => {
    if (shouldTrackAgeGateEvents) {
      segmentTrack(AGE_GATE_UNDER_AGE_CLICKED)
      setTimeout(() => {
        window.location.assign(translateMessage(messageKeys['DISAGREE_URL']))
      }, 500)
    } else {
      window.location.assign(translateMessage(messageKeys['DISAGREE_URL']))
    }
  }

  return (
    <Button
      id='age-gate-disagree-button'
      className={styles.button}
      onClick={handleOnClick}
      variant='ghost'
    >
      <TranslatedTextMessage
        id='DISAGREE_TEXT'
        inlineData={{ age: minimumAge }}
      />
    </Button>
  )
}
