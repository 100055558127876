import React, { useEffect } from 'react'

import {
  AGE_GATE_LOADED,
  AGE_GATE_OF_AGE_CLICKED,
} from '@juullabs/juulio-bridge/common/constants/segmentEvents'
import { AgeGateUIPolicy, AgeGateConfig } from '@viewlio/types/src/AgeGate'

import { TranslatedLinkMessage } from 'components/ageGate/components/TranslatedLinkMessage'
import { SplitLayoutWithLogo } from 'components/common/SplitLayoutWithLogo'
import { segmentTrack } from 'lib/segment'

import commonStyles from '../common.module.scss'

import { DefaultBody } from './DefaultBody'
import { DefaultFooter } from './DefaultFooter'

export type Props = {
  ageGateUIPolicy: AgeGateUIPolicy
  config: AgeGateConfig
  isGeolocationLoading?: boolean
  onAgreeButtonClick(stateCode: string): void
  onRouteToLocatorButtonClick(stateCode: string): void
  shouldTrackAgeGateEvents: boolean
}

export const DefaultAgeGate: React.FC<Props> = ({
  isGeolocationLoading = false,
  config,
  ageGateUIPolicy,
  onRouteToLocatorButtonClick,
  onAgreeButtonClick,
  shouldTrackAgeGateEvents,
}) => {
  useEffect(() => {
    if (shouldTrackAgeGateEvents) {
      segmentTrack(AGE_GATE_LOADED)
    }
  }, [shouldTrackAgeGateEvents])

  const handleAgreeButtonClick = (stateCode: string) => {
    if (shouldTrackAgeGateEvents) {
      segmentTrack(AGE_GATE_OF_AGE_CLICKED)
      setTimeout(() => {
        onAgreeButtonClick(stateCode)
      }, 500)
    } else {
      onAgreeButtonClick(stateCode)
    }
  }

  const props = {
    ageGateUIPolicy,
    config: config.body,
    expiresDays: config.expiresDays,
    isGeolocationLoading,
    onAgreeButtonClick: handleAgreeButtonClick,
    onRouteToLocatorButtonClick,
    shouldTrackAgeGateEvents,
  }

  return (
    <SplitLayoutWithLogo
      contentBody={(
        <>
          {config.header.ageRequirement && (
            <div className={commonStyles.subsection}>
              <strong>
                <TranslatedLinkMessage
                  id={'AGE_REQUIREMENT_TEXT'}
                  link={'AGE_REQUIREMENT_URL'}
                  linkText={'AGE_REQUIREMENT_LINK_TEXT'}
                  inlineData={{ age: config.header.ageRequirement.age }}
                />
              </strong>
            </div>
          )}

          <DefaultBody {...props} />
        </>
      )}
      footer={<DefaultFooter config={config.footer} />}
    />
  )
}
